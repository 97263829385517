<template>
  <div class="dashboard-editor-container">
    <div class="wrapper">
      <el-row :gutter="20">
        <el-col :span="12">
          <today></today>
        </el-col>
        <el-col :span="12">
          <month></month>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="15">
          <transaction-line></transaction-line>
        </el-col>
        <el-col :span="9">
          <category-ranking></category-ranking>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="15">
          <passenger-flow></passenger-flow>
        </el-col>
        <el-col :span="9">
          <goods-ranking></goods-ranking>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import today from "./components/today";
import month from "./components/month";
import transactionLine from "./components/transactionLine";
import categoryRanking from "./components/categoryRanking";
import passengerFlow from "./components/passengerFlow";
import goodsRanking from "./components/goodsRanking";
import store from "@/store";
export default {
  //引入组件
  components: {
    today,
    month,
    transactionLine,
    categoryRanking,
    passengerFlow,
    goodsRanking,
  },
  data() {
    return {
      value1: true,
    };
  },
  ///////////////////////////////////////////////////////////////////////////////////////////
  //计算机属性
  ///////////////////////////////////////////////////////////////////////////////////////////
  computed: {},
  ///////////////////////////////////////////////////////////////////////////////////////////
  //监听页面加载，其参数为上个页面传递的数据，参数类型为Object（用于页面传参）
  ///////////////////////////////////////////////////////////////////////////////////////////
  created() {

    console.log(store.getters.userInfo)
  },

  ///////////////////////////////////////////////////////////////////////////////////////////
  //方法调用
  ///////////////////////////////////////////////////////////////////////////////////////////
  methods: {},
};
</script>

<style scoped lang="scss">
</style>