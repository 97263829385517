<template>
  <div class="today">
    <div class="today-top">
      <img src="@/assets/money.png" alt="" />
      <span>今日交易</span>
    </div>
    <div class="today-con">
      <div class="con-show">
        <p class="con-title">交易总额</p>
        <p class="con-total">
          <span>{{ transactionStatisticsDay.transactionTotal }} </span>
          <span style="font-size: 16px">元</span>
        </p>
      </div>
      <div class="con-show">
        <p class="con-title">交易数量</p>
        <p class="con-total">
          <span>{{ transactionStatisticsDay.transactionNumber }} </span>
          <span style="font-size: 16px">笔</span>
        </p>
      </div>
      <div class="con-show">
        <p class="con-title">检测数量</p>
        <p class="con-total">
          <span>{{ transactionStatisticsDay.detectNumber }} </span>
          <span style="font-size: 16px">个</span>
        </p>
      </div>
      <div class="con-show">
        <p class="con-title">客流量</p>
        <p class="con-total">
          <span>{{ transactionStatisticsDay.passengerFlowNumber }} </span>
          <span style="font-size: 16px">人</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
export default {
  //引入组件
  components: {},
  data() {
    return {
      transactionStatisticsDay: {
        transactionTotal: "",
        transactionNumber: 0,
        detectNumber: 0,
        passengerFlowNumber: 0,
      },
    };
  },
  ///////////////////////////////////////////////////////////////////////////////////////////
  //计算机属性
  ///////////////////////////////////////////////////////////////////////////////////////////
  computed: {},
  ///////////////////////////////////////////////////////////////////////////////////////////
  //监听页面加载，其参数为上个页面传递的数据，参数类型为Object（用于页面传参）
  ///////////////////////////////////////////////////////////////////////////////////////////
  mounted() {
    // this.getTransactionStatisticsDay();
  },
  ///////////////////////////////////////////////////////////////////////////////////////////
  //方法调用
  ///////////////////////////////////////////////////////////////////////////////////////////
  methods: {
    async getTransactionStatisticsDay() {
      let result = await api.common.get({
        apiName: "/system/index/transactionStatisticsDay",
      });
      this.transactionStatisticsDay = result.data;
    },
  },
};
</script>

<style scoped lang="scss">
</style>